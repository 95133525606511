export const ExpirationRiskColorMap: Record<string, number> = {
  //Default colors
  Color1: 1,
  Color2: 2,
  Color3: 3,
  Color4: 4,
  Color5: 5,
  Color6: 6,
  Color7: 7,
  Color8: 8,
  Color9: 9,
  Color10: 10,
  Color11: 11,

  //Status colors
  Expired: 30,
  Return: 31,
  Returned: 38, //TODO: Use another name for return savings. Using this temporarily for demo.
  Restock: 32,
  Restocked: 32,
  Recall: 33,
  Recalled: 33,
  Relocate: 34,
  Relocated: 39,
  'Relocate - At Risk': 35,
  'In Stock': 36,
  'No Stock': 37,

  //Region colors
  Southwest: 50,
  Southeast: 51,
  West: 52,
  East: 53,
  Mountain: 54,
  South: 55
};

export const SavingsColorMap2: Record<string, string> = {
  Relocate: '#F9D300',
  Relocated: '#F9D300',
  Return: '#BA8146',
  Returned: '#BA8146'
};

export const GetChartColorClass = (map: Record<string, string>, name: string): string | undefined => {
  if (Object.hasOwn(map, name)) {
    return map[name];
  }
  return undefined;
};

export const GetChartColorIndex = (map: Record<string, number>, name: string): number | undefined => {
  if (Object.hasOwn(map, name)) {
    return map[name];
  }
  return undefined;
};

